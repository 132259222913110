<template>
  <b-row class="neocondo-minh-100">
    <b-col
      class="neocondo-border-left-primary py-2 neocondo-vertical-align-center"
      :class="$device.isMobile ? 'ml-4' : 'ml-5'"
    >
      <Transports :transports="vertex" :displayCols="false" />
    </b-col>
  </b-row>
</template>

<script>
import Transports from '@/components/transports.vue'

export default {
  name: 'FlowChartVertex',
  components: {
    Transports
  },
  props: {
    vertex: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped></style>
