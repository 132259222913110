<template>
  <div>
    <b-card no-body class="neocondo-border-radius-medium">
      <b-card-header
        header-class="neocondo-background-white neocondo-border-radius-top-medium neocondo-border-bottom-gray neocondo-border-radius-bottom-medium pt-3"
      >
        <p class="neocondo-text-small neocondo-text-bold">{{ $t('general_information') }}</p>
      </b-card-header>
      <b-card-body class="px-4 py-4">
        <img
          :src="product.logo_url"
          class="neocondo-icon-tiny"
        >
        <p class="neocondo-text-mlarge neocondo-text-bold mt-3">{{ product.name }}</p>
        <p class="neocondo-text-xsmall neocondo-text-semibold neocondo-text-gray">{{ $t('by') }} {{ product.brand }}</p>
        <div
          v-if="product.methodology == 'ae' && product.grade"
          class="mt-3 mx-auto my-auto neocondo-text-center"
        >
          <img
            :src="
              'https://neocondo-assets.s3.eu-west-3.amazonaws.com/ae_grade_'
              + (product.grade || 'c')
              + '.png'
            "
            class="neocondo-icon-big"
          >
        </div>
        <div
          v-if="product.methodology == 'pefcr' && product.score_100"
          class="mt-3 mx-auto my-auto neocondo-text-center"
        >
          <b-row>
            <b-col class="px-0"></b-col>
            <b-col md="auto">
              <GaugeChart
                :data="gaugeChartData"
                :options="gaugeChartOptions"
                class="neocondo-w-graph-gauge neocondo-h-graph-gauge"
              />
              <b-row>
                <b-col class="text-left">
                  <p class="neocondo-text-tiny neocondo-text-bold neocondo-text-red">{{ $t('high_impact') }}</p>
                </b-col>
                <b-col class="text-right">
                  <p class="neocondo-text-tiny neocondo-text-bold neocondo-text-green">{{ $t('low_impact') }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="px-0"></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col class="mx-auto my-auto neocondo-text-center">
              <p class="neocondo-text-large neocondo-text-bold">{{ prettifyBigAmount(1e6 * product.score) }}<span class="text-medium"> µPts</span></p>
              <p class="neocondo-text-small neocondo-text-semibold">{{ $t('global_score') }}</p>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="product.lca_indicators && product.lca_indicators.length > 0"
          class="mt-4"
        >
          <p class="neocondo-text-small neocondo-text-bold mb-3">{{ $t('environmental_indicators') }}</p>
          <ACVIndicator
            v-for="(indicator, index) in product.lca_indicators"
            :key="index"
            :class="{ 'mt-2': index > 0 }"
            :indicator="indicator"
            :value="indicator.value"
            :median="indicator.median"
          />
        </div>
        <div v-if="product.tags && product.tags.length > 0">
          <p class="neocondo-text-small neocondo-text-bold mt-4">{{ $t('characteristics') }}</p>
          <b-row>
            <b-col
              v-for="(tag, tagIndex) in product.tags"
              :key="'tag' + tagIndex"
              cols="auto"
              class="mt-2"
            >
              <b-button
                :id="`Tooltip-tag-${tagIndex}`"
                size="sm"
                class="neocondo-btn-primary"
              >
                <b-row>
                  <b-col cols="auto" class="my-auto pr-0">
                    <img :src="
                      'https://neocondo-assets.s3.eu-west-3.amazonaws.com/'
                      + tag.icon
                      "
                      class="neocondo-icon-xtiny"
                    >
                  </b-col>
                  <b-col class="my-auto">
                    <p class="neocondo-text-xsmall">{{ tag.name }}</p>
                  </b-col>
                </b-row>
              </b-button>
              <b-tooltip
                :target="`Tooltip-tag-${tagIndex}`"
                placement="bottom"
                custom-class="neocondo-w-tooltip"
              >
                {{ tag.description }}
              </b-tooltip>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
    <b-card
      v-if="product.composition && product.composition.length > 0"
      no-body
      class="neocondo-border-radius-medium mt-3"
    >
      <b-card-header
        header-class="neocondo-background-white neocondo-border-radius-top-medium neocondo-border-bottom-gray neocondo-border-radius-bottom-medium pt-3"
      >
        <p class="neocondo-text-small neocondo-text-bold">{{ $t('composition') }}</p>
      </b-card-header>
      <b-card-body class="px-4 py-3">
        <div
          v-for="(part, partIndex) in product.composition"
          :key="partIndex"
          :class="{ 'mt-3' : partIndex > 0 }"
        >
          <p class="neocondo-text-xsmall neocondo-text-semibold">{{ part.name }}</p>
          <div
            v-for="(comp, compIndex) in part.components"
            :key="compIndex"
          >
            <b-row v-b-toggle="'collapse-material-' + comp._id">
              <b-col v-if="comp.icon" cols="auto" class="my-auto pr-0">
                <img :src="'https://neocondo-assets.s3.eu-west-3.amazonaws.com/' + comp.icon" class="neocondo-icon-xtiny">
              </b-col>
              <b-col class="my-auto">
                <p class="neocondo-text-xsmall neocondo-text-semibold neocondo-text-gray">{{ comp.name }} : {{ comp.quantity.toFixed(0) }} %</p>
              </b-col>
              <b-col v-if="comp.description" cols="auto" class="mb-auto">
                <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/dropdown-up-gray.svg" class="neocondo-when-open neocondo-icon-micro">
                <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/dropdown-down-gray.svg" class="neocondo-when-closed neocondo-icon-micro">
              </b-col>
            </b-row>
            <b-collapse
              v-if="comp.description || comp.recycled || comp.organic || comp.label_icon"
              :id="'collapse-material-' + comp._id"
              class="py-3"
            >
              <b-row v-if="comp.recycled" class="mb-2">
                <b-col cols="auto" class="my-auto">
                  <img
                    src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/recycled.png"
                    class="neocondo-icon-xtiny"
                  >
                </b-col>
                <b-col class="my-auto pl-0">
                  <p class="neocondo-text-xsmall neocondo-text-gray">{{ $t('recycled_material') }}</p>
                </b-col>
              </b-row>
              <b-row v-if="comp.organic" class="mb-2">
                <b-col cols="auto" class="my-auto">
                  <img
                    src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/organic.png"
                    class="neocondo-icon-xtiny"
                  >
                </b-col>
                <b-col class="my-auto pl-0">
                  <p class="neocondo-text-xsmall neocondo-text-gray">{{ $t('organic_material') }}</p>
                </b-col>
              </b-row>
              <p class="neocondo-text-xsmall neocondo-text-gray" v-html="comp.description"></p>
              <b-row v-if="comp.label_icon" class="mt-3">
                <b-col cols="auto" class="my-auto pr-0">
                  <img :src="comp.label_icon" class="neocondo-icon-tiny">
                </b-col>
                <b-col class="my-auto">
                  <p class="neocondo-text-xsmall neocondo-text-gray">
                    {{ $t('material_certified') }}
                    <a
                      :href="comp.label_link"
                      target="_blank"
                      class="neocondo-text-gray neocondo-text-underline"
                    >{{ comp.label_name }}</a>
                  </p>
                </b-col>
              </b-row>
              <!-- <p class="neocondo-text-xsmall neocondo-text-gray mt-3">
                <span class="neocondo-text-semibold">Effet de serre</span> : {{ 1e3 * comp.co2.toPrecision(2) }} kg CO2e / kg<span v-if="comp.co2_reference"> ({{ comp.co2_reference.toPrecision(2) }}% par rapport au matériau conventionnel)</span>
              </p>
              <p class="neocondo-text-xsmall neocondo-text-gray mt-1">
                <span class="neocondo-text-semibold">Eutrophisation des eaux douces</span> : {{ 1e9 * comp.eutrophisation.toPrecision(2) }} µg Pe / kg<span v-if="comp.eutrophisation_reference"> ({{ comp.eutrophisation_reference.toPrecision(2) }}% par rapport au matériau conventionnel)</span>
              </p>
              <div v-if="comp.assets.length > 0">
                <p class="neocondo-text-xsmall neocondo-text-semibold neocondo-text-gray mt-3">Pour en savoir plus</p>
                <a
                  v-for="(asset, index) in comp.assets"
                  :key="index"
                  :href="asset.url"
                  target="_blank"
                  class="neocondo-text-xsmall neocondo-text-gray mt-2"
                >{{ asset.name }}</a>
              </div> -->
            </b-collapse>
          </div>
        </div>
        <div class="mt-3">
          <p
            v-if="product.recycled_materials > 0"
            class="text-xsmall text-gray my-1"
          >{{ $t('product_contains_recycled', { share: product.recycled_materials }) }}</p>
          <p
            v-if="product.recycled_packaging > 0"
            class="text-xsmall text-gray my-1"
          >{{ $t('packaging_contains_recycled', { share: product.recycled_packaging }) }}</p>
          <p
            v-if="product.microplastics"
            class="text-xsmall text-gray my-1"
          >{{ $t('microplastics') }}</p>
        </div>
      </b-card-body>
    </b-card>
    <b-card
      v-if="product.reach && product.reach.chemicals_used"
      no-body
      class="neocondo-border-radius-medium"
    >
      <b-card-header
        header-class="neocondo-background-white neocondo-border-radius-top-medium neocondo-border-bottom-gray neocondo-border-radius-bottom-medium pt-3"
      >
        <p class="neocondo-text-small neocondo-text-bold">{{ $t('dangerous_substances') }}</p>
      </b-card-header>
      <b-card-body class="px-4 py-3">
        <b-row>
          <b-col cols="auto" class="my-auto">
            <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/chemicals.png" class="neocondo-icon-xtiny">
          </b-col>
          <b-col class="my-auto pl-0">
            <p
              v-if="product.reach.chemicals_category == 'dangerous'"
              class="neocondo-text-xsmall neocondo-text-gray"
            >{{ $t('contains_dangerous_substances') }}</p>
            <p
              v-if="product.reach.chemicals_category == 'svhc'"
              class="neocondo-text-xsmall neocondo-text-gray"
            >{{ $t('contains_svhc') }}</p>
          </b-col>
        </b-row>
        <ul class="mt-2">
          <li
            v-for="(chemical, chemicalIndex) in product.reach.list_chemicals"
            :key="chemicalIndex"
            class="neocondo-text-xsmall neocondo-text-gray"
          >{{ chemical }}</li>
        </ul>
      </b-card-body>
    </b-card>
    <b-card
      v-if="product.lca_stages && product.lca_stages.length > 0"
      no-body
      class="neocondo-border-radius-medium mt-3"
    >
      <b-card-header
        header-class="neocondo-background-white neocondo-border-radius-top-medium neocondo-border-bottom-gray neocondo-border-radius-bottom-medium pt-3"
      >
        <p class="neocondo-text-small neocondo-text-bold">{{ $t('lca') }}</p>
      </b-card-header>
      <b-card-body class="px-4 py-4">
        <div
          v-for="(category, categoryIndex) in product.lca_stages.filter(cat => cat.value)"
          :key="categoryIndex"
          class="mb-2"
        >
          <b-row v-b-toggle="'collapse-acv-' + categoryIndex">
            <b-col cols="2" class="my-auto pr-0 neocondo-text-center">
              <img :src="'https://neocondo-assets.s3.eu-west-3.amazonaws.com/' + category.icon" class="neocondo-icon-xtiny">
            </b-col>
            <b-col class="my-auto pl-0">
              <p class="neocondo-text-xsmall neocondo-text-semibold neocondo-text-gray">{{ category.name }} : {{ category.value.toFixed(0) }} %</p>
            </b-col>
            <b-col cols="auto" class="mb-auto">
              <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/dropdown-up-gray.svg" class="neocondo-when-open neocondo-icon-micro">
              <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/dropdown-down-gray.svg" class="neocondo-when-closed neocondo-icon-micro">
            </b-col>
          </b-row>
          <b-collapse :id="'collapse-acv-' + categoryIndex" class="mt-3">
            <p class="neocondo-text-xsmall neocondo-text-gray neocondo-text-wrap" v-html="category.description"></p>
          </b-collapse>
        </div>
      </b-card-body>
    </b-card>
    <b-card
      v-if="product.traceability"
      no-body
      class="neocondo-border-radius-medium mt-3"
    >
      <b-card-header
        header-class="neocondo-background-white neocondo-border-radius-top-medium neocondo-border-bottom-gray neocondo-border-radius-bottom-medium pt-3"
      >
        <p class="neocondo-text-small neocondo-text-bold">{{ $t('product_production') }}</p>
      </b-card-header>
      <b-card-body class="px-0 py-0">
        <TraceabilityMap ref="suppliersMap" :supplyChain="product.traceability" />
        <TraceabilityFlowChart :supplyChain="product.traceability" display="mobile" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ACVIndicator from '@/components/acvindicator.vue'
import TraceabilityMap from '@/components/traceabilitymap.vue'
import TraceabilityFlowChart from '@/components/traceabilityflowchart.vue'
import GaugeChart from '@/components/gaugechart.vue'
import { gaugeChartOptions, orderedColors } from '@/plugins/charts'

export default {
  name: 'AffichageConsommateurs',
  components: {
    ACVIndicator,
    TraceabilityMap,
    TraceabilityFlowChart,
    GaugeChart
  },
  props: {
    product: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      gaugeChartOptions: {}
    }
  },
  mounted() {
    this.gaugeChartOptions = gaugeChartOptions
  },
  computed: {
    gaugeChartData() {
      return {
        labels: ['E', 'D', 'C', 'B', 'A'],
        datasets: [
          {
            label: 'Note globale',
            backgroundColor: [...orderedColors].reverse(),
            borderWidth: 0,
            hoverBorderWidth: 0,
            data: [12.5, 25, 25, 25, 12.5],
            needleValue: this.product ? this.product.score_100 : 0
          }
        ]
      }
    }
  },
  methods: {
    prettifyBigAmount(amount) {
      return parseInt(amount.toFixed(0)).toLocaleString()
    }
  }
}
</script>

<style scoped></style>
