import axios from 'axios'

const client = axios.create({})

client.interceptors.request.use(
  config => {
    config.metadata = { startTime: new Date() }
    return config
  }
)

client.interceptors.response.use(
  response => {
    response.config.metadata.endTime = new Date()
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime
    return Promise.resolve(response)
  },
  error => {
    error.config.metadata.endTime = new Date()
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime
    return Promise.resolve({
      status: error.response ? error.response.status : 404,
      duration: error.duration,
      data: {
        status_code: 500,
        error: 'generic_error'
      }
    })
  }
)

export default client