<template>
  <b-row class="h-100">
    <b-col>
      <b-card
        class="neocondo-card-no-shadow neocondo-card-primary h-100"
        :body-class="'d-flex flex-column py-2 my-auto' + (isMobile ? ' px-3' : '')"
        v-b-toggle="`collapse-node-${node._id}`"
      >
        <div class="my-auto">
          <b-row>
            <b-col :cols="isMobile ? 12 : 'auto'" class="my-auto pr-1 py-1">
              <img
                :src="'https://neocondo-assets.s3.eu-west-3.amazonaws.com/' + (node.icon ? node.icon : 'apparel.svg')"
                class="neocondo-icon-small"
              >
            </b-col>
            <b-col class="my-auto py-1">
              <p
                class="neocondo-text-bold"
                :class="isMobile ? 'neocondo-text-xsmall' : 'neocondo-text-xsmall'"
              >{{ node.name || $t('production_step') }}</p>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col class="my-auto">
              <div>
                <b-row>
                  <b-col
                    v-if="!external && node.supplier.identified"
                    cols="auto"
                    class="my-auto pr-0"
                  >
                    <img
                      v-b-tooltip.hover
                      title="Fournisseur identifié"
                      src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/link.png"
                      class="neocondo-icon-xtiny"
                    >
                  </b-col>
                  <b-col class="my-auto">
                    <p
                      v-if="node.supplier.name"
                      class="neocondo-text-semibold neocondo-text-tiny neocondo-text-gray"
                    >{{ titleCase(node.supplier.name) }}</p>
                  </b-col>
                </b-row>
                <p
                  v-if="node.supplier.address"
                  class="neocondo-text-tiny neocondo-text-gray"
                >{{ node.supplier.address }}</p>
                <b-row v-if="node.supplier.country">
                  <b-col
                    v-if="node.supplier.country_code"
                    cols="auto"
                    class="my-auto pr-0"
                  >
                    <img
                      :src="`https://neocondo-assets.s3.eu-west-3.amazonaws.com/countries/${node.supplier.country_code.toLowerCase()}.svg`"
                      class="neocondo-icon-xtiny"
                    >
                  </b-col>
                  <b-col class="my-auto pl-3">
                    <p
                      class="neocondo-text-tiny neocondo-text-gray"
                    >{{ node.supplier.country }}, {{ node.supplier.continent }}</p>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              v-if="
                !isMobile
                && (
                  node.label_status
                  || node.supplier.description
                  || (node.supplier.assets && node.supplier.assets.length > 0)
                )
              "
              cols="auto"
              class="my-auto pl-0 py-1"
            >
              <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/dropdown-up-gray.svg" class="when-open neocondo-icon-micro">
              <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/dropdown-down-gray.svg" class="when-closed neocondo-icon-micro">
            </b-col>
          </b-row>
          <b-collapse :id="`collapse-node-${node._id}`" visible>
            <div v-if="node.label_status" class="mt-3">
              <b-row>
                <b-col cols="auto">
                  <img :src="node.label_status.icon" class="neocondo-icon-xsmall">
                </b-col>
                <b-col class="pl-0">
                  <p class="neocondo-text-tiny neocondo-text-semibold neocondo-text-gray">{{ node.label_status.name }}</p>
                  <b-row v-if="node.label_status.status == 'valid'">
                    <b-col md="auto" class="my-auto pr-0">
                      <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/found.svg" class="neocondo-icon-tiny">
                    </b-col>
                    <b-col class="my-auto">
                      <p class="neocondo-text-tiny neocondo-text-gray">Validé</p>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col md="auto" class="my-auto pr-0">
                      <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/missing.svg" class="neocondo-icon-tiny">
                    </b-col>
                    <b-col class="my-auto neocondo-text-left">
                      <p class="neocondo-text-tiny neocondo-text-gray">{{ $t(`${node.label_status.error}_title`) }}</p>
                      <p class="neocondo-text-tiny neocondo-text-gray">{{ $t(`${node.label_status.error}_description`) }}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <p
              v-if="node.supplier.description"
              class="neocondo-text-gray neocondo-text-wrap mt-3 mb-1"
              :class="isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
              v-html="node.supplier.description"
            ></p>
            <div v-if="node.supplier.assets && node.supplier.assets.length > 0" class="mt-3">
              <b-row
                v-for="(asset, assetIndex) in node.supplier.assets"
                :key="assetIndex"
                class="mb-2"
              >
                <b-col v-if="asset.type == 'video'">
                  <p
                    class="neocondo-text-semibold neocondo-text-gray"
                    :class="isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
                  >{{ asset.title }}</p>
                  <b-embed
                    type="iframe"
                    aspect="16by9"
                    :src="asset.url"
                    allowfullscreen
                  ></b-embed>
                </b-col>
                <b-col v-if="asset.type == 'link'">
                  <a
                    :href="asset.url"
                    target="_blank"
                    class="neocondo-text-semibold neocondo-text-gray neocondo-text-underline"
                    :class="isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
                  >{{ asset.title }}</a>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'FlowChartNode',
  props: {
    node: {
      type: Object,
      default() {
        return {}
      }
    },
    external: {
      type: Boolean,
      default: true
    },
    display: {
      type: String,
      default: 'desktop'
    }
  },
  computed: {
    isMobile() {
      return this.$device.isMobile || this.display == 'mobile'
    }
  },
  methods: {
    titleCase(str) {
      if (!str) {
        return
      }
      return str.trim().split(' ').map(
        word => {
          return word[0].toUpperCase() + word.substr(1).toLowerCase()
        }
      ).reduce((a, b) => a + ' ' + b, '')
    }
  }
}
</script>

<style scoped></style>
