<template>
  <div :style="{ height: $device.mobile ? '60vw' : height + 'vw' }">
    <l-map :zoom="2" :maxZoom="6" :center="[30,65]" :options="{attributionControl: false}" ref="map">
      <l-tile-layer url="https://maps.geoapify.com/v1/tile/carto/{z}/{x}/{y}.png?&apiKey=7a10ff9c3ab740f391d4eafa44757da6"></l-tile-layer>
      <template
        v-for="(group, groupIndex) in suppliersMap"
      >
        <LeafletMarker
          v-if="group.length == 1"
          :key="groupIndex"
          :supplier="group[0]"
        />
        <v-marker-cluster v-else :key="groupIndex">
          <LeafletMarker
            v-for="(supplier, supplierIndex) in group"
            :key="'marker' + 100 * groupIndex + supplierIndex"
            :supplier="supplier"
          />
        </v-marker-cluster>
      </template>
      <l-polyline
        :lat-lngs="transport.map(trip => mapLine(trip)).filter(trip => trip)"
      ></l-polyline>
    </l-map>
  </div>
</template>

<script>
import LeafletMarker from '@/components/leafletmarker'

export default {
  name: 'TraceabilityMap',
  components: {
    LeafletMarker
  },
  props: {
    supplyChain: {
      type: Object,
      default() {
        return {}
      }
    },
    height: {
      type: Number,
      default: 15
    }
  },
  computed: {
    suppliers() {
      return this.supplyChain ? this.supplyChain.suppliers : []
    },
    transport() {
      return this.supplyChain ? this.supplyChain.transport : []
    },
    suppliersMap() {
      let result = []
      if (this.supplyChain) {
        result.push([this.suppliers[0]])
        this.suppliers.slice(1).forEach(
          supplier => {
            let last_group = result[result.length - 1]
            if (
              (supplier.latitude == last_group[last_group.length - 1].latitude)
              && (supplier.longitude == last_group[last_group.length - 1].longitude)
            ) {
              last_group.push(supplier)
            } else {
              result.push([supplier])
            }
          }
        )
      }
      return result
    }
  },
  watch: {
    suppliers: {
      handler() { this.initMapZoom() },
      deep: true
    }
  },
  mounted() {
    this.initMapZoom()
  },
  methods: {
    mapLine(trip) {
      let start = this.suppliers.find(supplier => supplier._id == trip.start)
      let end = this.suppliers.find(supplier => supplier._id == trip.end)
      if (start && end) {
        return [
          [start.supplier.latitude, start.supplier.longitude],
          [end.supplier.latitude, end.supplier.longitude]
        ]
      }
      return null
    },
    min(arr) {
      return arr.reduce((a, b) => a < b ? a : b, Infinity)
    },
    max(arr) {
      return arr.reduce((a, b) => a > b ? a : b, -Infinity)
    },
    initMapZoom() {
      if (!this.$refs.map) return

      let suppliers = this.suppliers.filter(
        supplier => supplier.supplier.latitude && supplier.supplier.longitude
      )
      if (suppliers.length > 0) {
        let latitudes = suppliers.map(supplier => supplier.supplier.latitude)
        let longitudes = suppliers.map(supplier => supplier.supplier.longitude)
        let minLat = this.min(latitudes), maxLat = this.max(latitudes)
        let minLng = this.min(longitudes), maxLng = this.max(longitudes)
        if (minLat && maxLat && minLng && maxLng) {
          this.$refs.map.fitBounds([
            [minLat, minLng],
            [maxLat, maxLng]
          ])
        }
      }
    }
  }
}
</script>

<style scoped></style>
