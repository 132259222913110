const gaugeChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  rotation: -1.0 * Math.PI,
  circumference: Math.PI,
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  },
  plugins: {
    labels: {
      render: 'label',
      arc: false,
      fontColor: function (args) {
        if (
          ((args.label == 'E') && (args.dataset.needleValue < 12.5))
          || ((args.label == 'D') && (args.dataset.needleValue >= 12.5) && (args.dataset.needleValue < 37.5))
          || ((args.label == 'C') && (args.dataset.needleValue >= 37.5) && (args.dataset.needleValue < 62.5))
          || ((args.label == 'B') && (args.dataset.needleValue >= 62.5) && (args.dataset.needleValue < 87.5))
          || ((args.label == 'A') && (args.dataset.needleValue >= 87.5))
         ) {
          return '#000000'
        }
        return '#B0B0B0'
      },
      fontStyle: 'bold',
      fontSize: function (args) {
        if (
          ((args.label == 'E') && (args.dataset.needleValue < 12.5))
          || ((args.label == 'D') && (args.dataset.needleValue >= 12.5) && (args.dataset.needleValue < 37.5))
          || ((args.label == 'C') && (args.dataset.needleValue >= 37.5) && (args.dataset.needleValue < 62.5))
          || ((args.label == 'B') && (args.dataset.needleValue >= 62.5) && (args.dataset.needleValue < 87.5))
          || ((args.label == 'A') && (args.dataset.needleValue >= 87.5))
         ) {
          return 35
        }
        return 16
      }
    }
  }
}
const orderedColors = ['#009f37', '#B3FF00', '#FFFF00', '#FFB300', '#FF0000']

export { gaugeChartOptions, orderedColors }
