var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"py-3"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('Transports',{attrs:{"transports":_vm.transportSum}})],1)],1),_c('div',{class:[
          _vm.isMobile ? 'px-2' : 'px-3',
          {'mt-4': _vm.totalTransport > 0}
        ]},[_c('b-row',{staticClass:"neocondo-child-scrollable-width neocondo-no-scrollbar justify-content-center"},[_c('b-col',{staticClass:"neocondo-child-scrollable-width neocondo-no-scrollbar"},[_c('b-row',{staticClass:"justify-content-center",style:({'width': `calc(${_vm.flowChartWidth}% + 30px)`})},_vm._l((_vm.flowChart.upstream),function(col,colIndex){return _c('b-col',{key:`upstream-col-${colIndex}`,class:[
                  `neocondo-col-${_vm.flowChartColumnWidth * _vm.flowChartNbColumnsUpstream}-${_vm.flowChartColumnWidth * col[0].length}`,
                  {
                    'pl-0': colIndex > 0,
                    'pr-0': colIndex < _vm.flowChart.upstream.length - 1
                  }
                ],attrs:{"align-self":"end"}},_vm._l((col),function(colRow,colRowIndex){return _c('b-row',{key:`upstream-col-${colIndex}-colRow-${colRowIndex}`,staticClass:"justify-content-center"},_vm._l((colRow),function(elmt,elmtIndex){return _c('b-col',{key:`upstream-col-${colIndex}-colRow-${colRowIndex}-elmt-${elmtIndex}`,class:{
                      'pl-0': elmt.type == 'hr' && elmtIndex > 0,
                      'pr-0': elmt.type == 'hr' && elmtIndex < colRow.length - 1,
                      'pl-4': elmt.type != 'hr' && elmtIndex == 0,
                      'pl-2': elmt.type != 'hr' && elmtIndex > 0,
                      'pr-4': elmt.type != 'hr' && elmtIndex == colRow.length - 1,
                      'pr-2': elmt.type != 'hr' && elmtIndex < colRow.length - 1
                    },attrs:{"cols":12 / col[0].length}},[(elmt.type == 'vertex')?_c('FlowChartVertex',{attrs:{"vertex":elmt}}):_vm._e(),(elmt.type == 'node')?_c('FlowChartNode',{attrs:{"node":elmt,"external":_vm.external,"display":_vm.display}}):_vm._e(),(elmt.type == 'hr')?_c('div',{staticClass:"border-top-primary",class:{
                        'neocondo-hr-line-right-flowchart': elmt.hrType == 'right',
                        'neocondo-hr-line-left-flowchart': elmt.hrType == 'left'
                      }}):_vm._e(),(elmt.type == 'vr')?_c('div',{staticClass:"neocondo-h-smallline"},[_c('b-row',{staticClass:"minh-100"},[_c('b-col',{staticClass:"ml-5 neocondo-border-left-primary"})],1)],1):_vm._e()],1)}),1)}),1)}),1)],1)],1),_vm._l((_vm.flowChart.assemblystream),function(elmt,elmtIndex){return _c('b-row',{key:`assemblystream-elmt-${elmtIndex}`,staticClass:"justify-content-center"},[_c('b-col',{staticClass:"px-4",attrs:{"cols":_vm.flowChartLargeColumnWidth}},[(elmt.type == 'vertex')?_c('FlowChartVertex',{attrs:{"vertex":elmt}}):_vm._e(),(elmt.type == 'node')?_c('FlowChartNode',{attrs:{"node":elmt,"external":_vm.external,"display":_vm.display}}):_vm._e(),(elmt.type == 'hr')?_c('div',{staticClass:"border-top-primary",class:{
                'neocondo-hr-line-right-flowchart': elmt.hrType == 'right',
                'neocondo-hr-line-left-flowchart': elmt.hrType == 'left'
              }}):_vm._e(),(elmt.type == 'vr')?_c('div',{staticClass:"neocondo-h-smallline"},[_c('b-row',{staticClass:"neocondo-minh-100"},[_c('b-col',{staticClass:"ml-5 neocondo-border-left-primary"})],1)],1):_vm._e()],1)],1)}),_c('b-row',{staticClass:"justify-content-center",style:({'width': `flowChartWidth}%`})},_vm._l((_vm.flowChart.downstream),function(col,colIndex){return _c('b-col',{key:`downstream-col-${colIndex}`,staticClass:"px-0",class:[
              `neocondo-col-${_vm.flowChartColumnWidth * _vm.flowChartNbColumnsDownstream}-${_vm.flowChartColumnWidth}`
            ],attrs:{"align-self":"start"}},_vm._l((col),function(elmt,elmtIndex){return _c('div',{key:`downstream-elmt-${elmtIndex}`,class:{
                'pl-4': colIndex == 0,
                'pl-2': elmt.type != 'hr' && colIndex > 0,
                'pr-4': colIndex == _vm.flowChart.downstream.length - 1,
                'pr-2': elmt.type != 'hr' && colIndex < _vm.flowChart.downstream.length - 1
              }},[(elmt.type == 'vertex')?_c('FlowChartVertex',{attrs:{"vertex":elmt}}):_vm._e(),(elmt.type == 'node')?_c('FlowChartNode',{attrs:{"node":elmt,"external":_vm.external,"display":_vm.display}}):_vm._e(),(elmt.type == 'hr')?_c('div',{staticClass:"border-top-primary",class:{
                  'neocondo-hr-line-right-flowchart-downstream': elmt.hrType == 'right',
                  'neocondo-hr-line-left-flowchart-downstream': elmt.hrType == 'left'
                }}):_vm._e(),(elmt.type == 'vr')?_c('div',{staticClass:"neocondo-h-smallline"},[_c('b-row',{staticClass:"minh-100"},[_c('b-col',{staticClass:"ml-5 border-left-primary"})],1)],1):_vm._e()],1)}),0)}),1)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }