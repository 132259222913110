<template>
  <div id="neocondo">
    <b-modal
      id="neocondo-widget"
      hide-header
      hide-footer
      modal-class="neocondo-h-109h"
      :dialog-class="{ 'neocondo-widget': !$device.mobile, 'neocondo-widget-mobile': $device.mobile }"
      content-class="neocondo-card-ae"
      body-class="neocondo-h-100h neocondo-content-ae"
      scrollable
    >
      <b-row>
        <b-col cols="auto" class="my-auto px-0">
          <b-dropdown
            toggle-class="neocondo-btn-none"
            menu-class="neocondo-locale-switch"
          >
            <template #button-content>
              <img
                :src="`https://neocondo-assets.s3.eu-west-3.amazonaws.com/${$i18n.locale}.png`"
                class="neocondo-icon-mini pr-2"
              >
            </template>
            <b-dropdown-item-button
              v-for="(locale, localeIndex) in $i18n.availableLocales.filter(loc => loc !== $i18n.locale)"
              :key="'locale-' + localeIndex"
              @click="$i18n.locale = locale"
              button-class="neocondo-w-auto py-1"
            >
              <img
                :src="`https://neocondo-assets.s3.eu-west-3.amazonaws.com/${locale}.png`"
                class="neocondo-icon-mini"
              >
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
        <b-col class="px-0"></b-col>
        <b-col cols="auto" class="my-auto px-sm-0">
          <b-button
            @click="closeAffichageEnvironnemental"
            class="neocondo-btn-none px-0 py-0"
          >
            <img
              src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/close.svg"
              class="neocondo-icon-xmini"
            >
          </b-button>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col cols="10" class="neocondo-text-center my-auto">
          <p class="neocondo-text-mlarge neocondo-text-bold">{{ $t('product_information') }}</p>
        </b-col>
      </b-row>
      <div v-if="loading" class="my-5 neocondo-text-center">
        <b-spinner></b-spinner>
        <p class="neocondo-text-xsmall mt-3">{{ $t('loading_message') }}</p>
      </div>
      <div v-if="showContent" class="my-4">
        <p v-if="errorMessage" class="neocondo-text-xsmall mt-5">{{ errorMessage }}</p>
        <AffichageConsommateurs
          v-else
          :product="product"
          ref="affichageConsommateurs"
        />
      </div>
      <b-row class="justify-content-center">
        <b-col cols="auto" class="neocondo-text-center my-auto">
          <a href="https://www.neocondo.fr" target="_blank">
            <p class="neocondo-text-xsmall neocondo-text-semibold neocondo-text-primary">Powered by</p>
            <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/logo_blue.png" class="neocondo-icon-tiny">
          </a>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import AffichageConsommateurs from '@/components/affichageconsommateurs.vue'

export default {
  name: 'App',
  components: {
    AffichageConsommateurs
  },
  data() {
    return {
      currentParams: null,
      product: null,
      errorMessage: null,
      showContent: false,
      loading: false
    }
  },
  watch: {
    '$i18n.locale'() {
      this.fetchProductData()
    }
  },
  methods: {
    async affichageEnvironnemental(params) {
      this.currentParams = params
      this.$bvModal.show('neocondo-widget')
      if (!this.product) {
        await this.fetchProductData()
      }

      await this.$nextTick()
      if (this.$refs.affichageConsommateurs) {
        await new Promise(resolve => setTimeout(resolve, 500)) 
        this.$refs.affichageConsommateurs.$refs.suppliersMap.$refs.map.mapObject.invalidateSize()
      }
    },
    closeAffichageEnvironnemental() {
      this.$bvModal.hide('neocondo-widget')
    },
    async fetchProductData() {
      this.loading = true
      this.showContent = false
      this.errorMessage = null

      let url = window.location.href
      let domain = (new URL(url)).hostname.split('.')
      if (domain.length >= 3) {
        domain = domain.slice(1)
      }
      domain = domain.join('.')
      let params = `domain=${domain}&locale=${this.$i18n.locale}&origin=widget`
      if (this.currentParams && this.currentParams.productKey) {
        params += `&product_key=${this.currentParams.productKey}`
      } else if (this.currentParams && this.currentParams.productReference) {
        params += `&product_reference=${this.currentParams.productReference}`
      } else {
        params += `&url=${url}`
      }

      // Fetch product data
      let response = await axios.get(
        `https://neocondo-api-public.herokuapp.com/api/product?${params}`
      )
      // Store tracking data
      axios.get(
        'https://www.neocondo.fr/api/products/affichage-consommateurs/record'
        + `?${params}`
        + `&status=${response.status}`
        + `&duration=${response.duration}`
      )

      if (response.data.status_code == 200) {
        if (response.data.product) {
          this.product = response.data.product
        } else {
          this.errorMessage = this.$t('generic_error')
        }
      } else {
        this.errorMessage = this.$t(response.data.error)
      }
      this.loading = false
      this.showContent = true
    }
  }
}
</script>

<style>
</style>
