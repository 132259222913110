<template>
  <div>
    <b-row :id="`Tooltip-target-${id}`">
      <b-col v-if="indicator.icon" cols="auto" class="my-auto pr-0">
        <img
          :src="'https://neocondo-assets.s3.eu-west-3.amazonaws.com/' + indicator.icon"
          class="neocondo-icon-small"
        >
      </b-col>
      <b-col class="mb-auto">
        <b-row>
          <b-col class="pr-0 neocondo-text-left">
            <p class="neocondo-text-xsmall neocondo-text-semibold neocondo-text-primary">{{ indicator.name }}</p>
          </b-col>
          <b-col v-if="median" class="pl-0 neocondo-text-right mt-auto">
            <p class="neocondo-text-xsmall neocondo-text-semibold neocondo-text-primary">{{ value.toPrecision(3) }} {{ indicator.unit }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-progress
              v-if="median"
              :max="2 * median"
              :class="aeScale ? 'neocondo-background-ae-scale' : 'neocondo-background-ae-light'"
            >
              <b-progress-bar
                :value="Math.max(0.05 * median, Math.min(value, 1.95 * median)) - 0.02 * median"
                :class="aeScale ? 'neocondo-background-transparent' : 'neocondo-background-ae-dark'"
              ></b-progress-bar>
              <b-progress-bar
                :value="0.04 * median"
                class="neocondo-background-ae-dark"
              ></b-progress-bar>
            </b-progress>
            <p v-else class="neocondo-text-tiny neocondo-text-semibold neocondo-text-primary">{{ value.toPrecision(3) }} {{ indicator.unit }}</p>
          </b-col>
        </b-row>
        <b-row v-if="aeScale">
          <b-col>
            <p class="neocondo-text-xsmall neocondo-text-bold" style="color: #FF0000;">E</p>
          </b-col>
          <b-col>
            <p class="neocondo-text-xsmall neocondo-text-bold" style="color: #FFB300;">D</p>
          </b-col>
          <b-col>
            <p class="neocondo-text-xsmall neocondo-text-bold" style="color: #FFFF00;">C</p>
          </b-col>
          <b-col>
            <p class="neocondo-text-xsmall neocondo-text-bold" style="color: #B3FF00;">B</p>
          </b-col>
          <b-col>
            <p class="neocondo-text-xsmall neocondo-text-bold" style="color: #009f37;">A</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-tooltip
      v-if="!!indicator.description"
      :target="`Tooltip-target-${id}`"
      :placement="tooltipPlacement"
      custom-class="neocondo-w-tooltip"
    >
      {{ indicator.name }} : {{ indicator.description }}
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ACVIndicator',
  props: {
    indicator: {
      type: Object,
      default() {
        return {}
      }
    },
    value: {
      type: Number,
      default: 0
    },
    median: {
      type: Number,
      default: null
    },
    tooltipPlacement: {
      type: String,
      default: 'bottom'
    },
    aeScale: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: (Math.random() * 1e17).toString(36)
    }
  }
}
</script>

<style scoped></style>
