var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-marker',{attrs:{"lat-lng":[_vm.supplier.supplier.latitude,_vm.supplier.supplier.longitude],"icon":_vm.getIcon(_vm.supplier)}},[_c('l-tooltip',{attrs:{"content":'<span class=\'neocondo-text-bold\'>'
      + _vm.supplier.name
      + '</span>'
      + (_vm.supplier.supplier.name
        ? '<br>' + _vm.supplier.supplier.name
        : ''
      )
      + (_vm.supplier.supplier.address
        ? '<br>' + _vm.shortenLongString(_vm.supplier.supplier.address)
        : ''
      )
      + '<br>'
      + _vm.supplier.supplier.country
      + ', '
      + _vm.supplier.supplier.continent}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }