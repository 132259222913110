<template>
  <div>
    <div class="py-3">
      <b-row class="justify-content-center">
        <b-col cols="auto">
          <Transports :transports="transportSum" />
        </b-col>
      </b-row>
      <div
        :class="[
          isMobile ? 'px-2' : 'px-3',
          {'mt-4': totalTransport > 0}
        ]"
      >
        <b-row class="neocondo-child-scrollable-width neocondo-no-scrollbar justify-content-center">
          <b-col class="neocondo-child-scrollable-width neocondo-no-scrollbar">
            <b-row
              class="justify-content-center"
              :style="{'width': `calc(${flowChartWidth}% + 30px)`}"
            >
              <b-col
                v-for="(col, colIndex) in flowChart.upstream"
                :key="`upstream-col-${colIndex}`"
                :class="[
                  `neocondo-col-${flowChartColumnWidth * flowChartNbColumnsUpstream}-${flowChartColumnWidth * col[0].length}`,
                  {
                    'pl-0': colIndex > 0,
                    'pr-0': colIndex < flowChart.upstream.length - 1
                  }
                ]"
                align-self="end"
              >
                <b-row
                  v-for="(colRow, colRowIndex) in col"
                  :key="`upstream-col-${colIndex}-colRow-${colRowIndex}`"
                  class="justify-content-center"
                >
                  <b-col
                    v-for="(elmt, elmtIndex) in colRow"
                    :key="`upstream-col-${colIndex}-colRow-${colRowIndex}-elmt-${elmtIndex}`"
                    :cols="12 / col[0].length"
                    :class="{
                      'pl-0': elmt.type == 'hr' && elmtIndex > 0,
                      'pr-0': elmt.type == 'hr' && elmtIndex < colRow.length - 1,
                      'pl-4': elmt.type != 'hr' && elmtIndex == 0,
                      'pl-2': elmt.type != 'hr' && elmtIndex > 0,
                      'pr-4': elmt.type != 'hr' && elmtIndex == colRow.length - 1,
                      'pr-2': elmt.type != 'hr' && elmtIndex < colRow.length - 1
                    }"
                  >
                    <FlowChartVertex
                      v-if="elmt.type == 'vertex'"
                      :vertex="elmt"
                    />
                    <FlowChartNode
                      v-if="elmt.type == 'node'"
                      :node="elmt"
                      :external="external"
                      :display="display"
                    />
                    <div
                      v-if="elmt.type == 'hr'"
                      class="border-top-primary"
                      :class="{
                        'neocondo-hr-line-right-flowchart': elmt.hrType == 'right',
                        'neocondo-hr-line-left-flowchart': elmt.hrType == 'left'
                      }"
                    ></div>
                    <div
                      v-if="elmt.type == 'vr'"
                      class="neocondo-h-smallline"
                    >
                      <b-row class="minh-100">
                        <b-col class="ml-5 neocondo-border-left-primary"></b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          v-for="(elmt, elmtIndex) in flowChart.assemblystream"
          :key="`assemblystream-elmt-${elmtIndex}`"
          class="justify-content-center"
        >
          <b-col :cols="flowChartLargeColumnWidth" class="px-4">
            <FlowChartVertex
              v-if="elmt.type == 'vertex'"
              :vertex="elmt"
            />
            <FlowChartNode
              v-if="elmt.type == 'node'"
              :node="elmt"
              :external="external"
              :display="display"
            />
            <div
              v-if="elmt.type == 'hr'"
              class="border-top-primary"
              :class="{
                'neocondo-hr-line-right-flowchart': elmt.hrType == 'right',
                'neocondo-hr-line-left-flowchart': elmt.hrType == 'left'
              }"
            ></div>
            <div
              v-if="elmt.type == 'vr'"
              class="neocondo-h-smallline"
            >
              <b-row class="neocondo-minh-100">
                <b-col class="ml-5 neocondo-border-left-primary"></b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row
          class="justify-content-center"
          :style="{'width': `flowChartWidth}%`}"
        >
          <b-col
            v-for="(col, colIndex) in flowChart.downstream"
            :key="`downstream-col-${colIndex}`"
            class="px-0"
            :class="[
              `neocondo-col-${flowChartColumnWidth * flowChartNbColumnsDownstream}-${flowChartColumnWidth}`
            ]"
            align-self="start"
          >
            <div
              v-for="(elmt, elmtIndex) in col"
              :key="`downstream-elmt-${elmtIndex}`"
              :class="{
                'pl-4': colIndex == 0,
                'pl-2': elmt.type != 'hr' && colIndex > 0,
                'pr-4': colIndex == flowChart.downstream.length - 1,
                'pr-2': elmt.type != 'hr' && colIndex < flowChart.downstream.length - 1
              }"
            >
              <FlowChartVertex
                v-if="elmt.type == 'vertex'"
                :vertex="elmt"
              />
              <FlowChartNode
                v-if="elmt.type == 'node'"
                :node="elmt"
                :external="external"
                :display="display"
              />
              <div
                v-if="elmt.type == 'hr'"
                class="border-top-primary"
                :class="{
                  'neocondo-hr-line-right-flowchart-downstream': elmt.hrType == 'right',
                  'neocondo-hr-line-left-flowchart-downstream': elmt.hrType == 'left'
                }"
              ></div>
              <div
                v-if="elmt.type == 'vr'"
                class="neocondo-h-smallline"
              >
                <b-row class="minh-100">
                  <b-col class="ml-5 border-left-primary"></b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import FlowChartVertex from '@/components/flowchartvertex.vue'
import FlowChartNode from '@/components/flowchartnode.vue'
import Transports from '@/components/transports.vue'

export default {
  name: 'TraceabilityFlowChart',
  components: {
    FlowChartVertex,
    FlowChartNode,
    Transports
  },
  props: {
    supplyChain: {
      type: Object,
      default() {
        return {}
      }
    },
    external: {
      type: Boolean,
      default() {
        return true
      }
    },
    display: {
      type: String,
      default: 'desktop'
    }
  },
  computed: {
    isMobile() {
      return this.$device.isMobile || this.display == 'mobile'
    },
    suppliers() {
      return this.supplyChain ? this.supplyChain.suppliers : []
    },
    transport() {
      return this.supplyChain ? this.supplyChain.transport : []
    },
    transportSum() {
      let totalWeight = this.max(this.transport, 'quantity')
      let transport = this.deepCopy(this.transport)
      let transports = ['train', 'truck', 'van', 'car', 'boat', 'airplane']
      transports.forEach(
        vehicule => {
          transport.forEach(
            trip => {
              trip[vehicule + '_qty'] = trip[vehicule] * trip.quantity / totalWeight
            }
          )
        }
      )
      return {
        train: this.sum(transport, 'train_qty'),
        truck: this.sum(transport, 'truck_qty'),
        van: this.sum(transport, 'van_qty'),
        car: this.sum(transport, 'car_qty'),
        boat: this.sum(transport, 'boat_qty'),
        airplane: this.sum(transport, 'airplane_qty')
      }
    },
    totalTransport() {
      return this.sum(Object.values(this.transportSum))
    },
    flowChart() {
      if (this.suppliers.length == 0) {
        return { upstream: [], assemblystream: [], downstream: [] }
      }

      let upstreamFlowChart = this.getUpstreamTopFlowChart(this.suppliers)
      let upstream = upstreamFlowChart[0]
      let suppliers = upstreamFlowChart[1]

      let hasUpstream = (
        upstream.length >= 2
        || (upstream.length > 0 && upstream[upstream.length - 1].length >= 2)
      )
      let downstreamFlowChart = this.getDownstreamFlowChart(suppliers, hasUpstream)
      let downstream = downstreamFlowChart[0]
      suppliers = downstreamFlowChart[1]

      upstreamFlowChart = this.getUpstreamBottomFlowChart(upstream, suppliers)
      upstream = upstreamFlowChart[0]
      suppliers = upstreamFlowChart[1]

      hasUpstream = upstream.length > 0
      let hasDownstream = downstream.length > 0
      let assemblystream = this.getAssemblystreamFlowChart(suppliers, hasUpstream, hasDownstream)
      return { upstream: upstream, assemblystream: assemblystream, downstream: downstream }
    },
    flowChartColumnWidth() {
      return this.isMobile ? 6 : 3
    },
    flowChartLargeColumnWidth() {
      return this.isMobile ? 12 : 3
    },
    flowChartNbColumnsUpstream() {
      return this.flowChart.upstream.reduce(
        (nbColumns, column) => nbColumns + column[0].length, 0
      )
    },
    flowChartNbColumnsDownstream() {
      return this.flowChart.downstream.length
    },
    flowChartWidth() {
      return 100 * Math.max(1, this.flowChartColumnWidth * this.flowChartNbColumnsUpstream / 12)
    }
  },
  methods: {
    deepCopy(inObject) {
      let outObject, value, key
      if (
        (typeof inObject !== "object")
        || (inObject instanceof Date)
        || (inObject instanceof File)
        || (inObject instanceof Blob)
        || (inObject === null)
       ) {
        return inObject
      }
      outObject = Array.isArray(inObject) ? [] : {}
      for (key in inObject) {
        value = inObject[key]
        outObject[key] = this.deepCopy(value)
      }
      return outObject
    },
    sum(arr, field = null) {
      if (field) {
        return arr.reduce((a, b) => a + b[field], 0)
      } else {
        return arr.reduce((a, b) => a + b, 0)
      }
    },
    max(arr, field = null) {
      if (field) {
        return arr.reduce(
          (a, b) => a > (b[field] ? b[field] : 0) ? a : b[field],
          0
        )
      } else {
        return arr.reduce(
          (a, b) => a > (b ? b : 0) ? a : b,
          0
        )
      }
    },
    filterSuppliers(suppliers, toFilter) {
      return suppliers.filter(
        supplier => !toFilter.find(obj => obj._id == supplier._id) 
      )
    },
    getUpstreamTopFlowChart(suppliers) {
      let tripsEnds = this.transport.map(trip => trip.end)
      if (tripsEnds.every(end => tripsEnds.indexOf(end) == tripsEnds.lastIndexOf(end))) {
        return [[], suppliers]
      }

      let materials = suppliers.filter(
        supplier => !this.transport.find(
          trip => trip.end == supplier._id
        )
      )
      suppliers = this.filterSuppliers(suppliers, materials)
      materials = materials.reduce(
        (hash, obj) => {
          let trip = this.transport.find(trip => trip.start == obj._id)
          return Object.assign(
            hash,
            {[trip.end]: (hash[trip.end] || []).concat({...obj, type: 'node'})}
          )
        },
        {}
      )
      materials = Object.values(materials)

      let upstream = materials.map(group => { return [group] })
      upstream.forEach(
        column => {
          column.push([])
          column[0].forEach(
            material => {
              let trip = this.transport.find(
                trip => trip.start == material._id
              )
              column[1].push({...trip, type: 'vertex'})
            }
          )
          if (column[0].length >= 2) {
            let hrArr = []
            hrArr.push({type: 'hr', hrType: 'right'})
            hrArr.push(...new Array(column[0].length - 2).fill({type: 'hr'}))
            hrArr.push({type: 'hr', hrType: 'left'})
            column.push(hrArr)
          }
          if (upstream.length >= 2 || column[0].length >= 2) {
            column.push([{type: 'vr'}])
          }
        }
      )

      return [upstream, suppliers]
    },
    getUpstreamBottomFlowChart(upstream, suppliers) {
      let currentStep, nextStep, currentTrip, upwardTrips, cnt
      upstream.forEach(
        (column, columnIndex) => {
          currentStep = column[0][0]
          let materialsId = column[0].map(material => material._id)
          cnt = 0
          while (cnt < 20) {
            currentTrip = this.transport.find(
              trip => trip.start == currentStep._id
            )
            if (!currentTrip) break
            if (cnt > 0) {
              column.push([{...currentTrip, type: 'vertex'}])
            }

            nextStep = suppliers.find(
              supplier => supplier._id == currentTrip.end
            )
            if (!nextStep) break
            upwardTrips = this.transport.filter(
              trip => (
                trip.end == nextStep._id
                && trip.start != currentStep._id
                && !materialsId.includes(trip.start)
              )
            )
            if (upwardTrips.length > 0) break
            column.push([{...nextStep, type: 'node'}])
            currentStep = nextStep

            cnt++
          }

          if (upstream.length >= 2) {
            let hrArr = []
            if (columnIndex == 0) {
              hrArr.push({type: 'hr', hrType: 'right'})
              hrArr.push(...new Array(column[0].length - 1).fill({type: 'hr'}))
            } else if (columnIndex == upstream.length - 1) {
              hrArr.push(...new Array(column[0].length - 1).fill({type: 'hr'}))
              hrArr.push({type: 'hr', hrType: 'left'})
            } else {
              hrArr.push(...new Array(column[0].length).fill({type: 'hr'}))
            }
            column.push(hrArr)
          }
        }
      )
      suppliers = this.filterSuppliers(suppliers, upstream.flat(Infinity))

      return [upstream, suppliers]
    },
    getDownstreamFlowChart(suppliers, hasUpstream) {
      let tripsStarts = this.transport.map(trip => trip.start)
      if (tripsStarts.every(start => tripsStarts.indexOf(start) == tripsStarts.lastIndexOf(start))) {
        return [[], suppliers]
      }

      let downstream = suppliers.filter(
        supplier => !this.transport.find(
          trip => trip.start == supplier._id
        )
      )
      downstream = downstream.map(step => { return [{...step, type: 'node'}] })

      let currentStep, currentTrip, previousSteps, previousStep, downwardTrips, cnt
      downstream.forEach(
        (channel, channelIndex) => {
          cnt = 0
          currentStep = channel[0]
          while (cnt < 20) {
            previousSteps = suppliers.filter(
              supplier => this.transport.find(
                trip => trip.start == supplier._id && trip.end == currentStep._id
              )
            )
            if (previousSteps.length == 0 || previousSteps.length >= 2) break
            previousStep = previousSteps[0]
            currentTrip = this.transport.find(
              trip => trip.start == previousStep._id && trip.end == currentStep._id
            )
            channel.push({...currentTrip, type: 'vertex'})
            downwardTrips = this.transport.filter(
              trip => trip.start == previousStep._id
            )
            if (downwardTrips.length >= 2) break
            channel.push({...previousStep, type: 'node'})
            currentStep = previousStep

            cnt++
          }
          if (hasUpstream) {
            channel.push({type: 'vr'})
          }
          if (downstream.length >= 2) {
            if (channelIndex == 0) {
              channel.push({type: 'hr', hrType: 'right'})
            } else if (channelIndex == downstream.length - 1) {
              channel.push({type: 'hr', hrType: 'left'})
            } else {
              channel.push({type: 'hr'})
            }
          }
          channel.reverse()
        }
      )
      suppliers = this.filterSuppliers(suppliers, downstream.flat(Infinity))

      return [downstream, suppliers]
    },
    getAssemblystreamFlowChart(suppliers, hasUpstream, hasDownstream) {
      let currentStep = suppliers.find(
        supplier => {
          let currentTrips = this.transport.filter(trip => trip.end == supplier._id)
          return (
            currentTrips.length == 0
            || !currentTrips.some(trip => suppliers.find(sup => sup._id == trip.start))
          )
        }
      )
      
      if (!currentStep) return []

      let assemblystream = []
      if (hasUpstream) {
        assemblystream.push({type: 'vr'})
      }
      assemblystream.push({...currentStep, type: 'node'})

      let currentTrip, nextStep, cnt = 0
      while (cnt < 20) {
        suppliers = this.filterSuppliers(suppliers, [currentStep])
        currentTrip = this.transport.find(
            trip => trip.start == currentStep._id
        )
        if (!currentTrip) break
        nextStep = suppliers.find(supplier => supplier._id == currentTrip.end)
        if (!nextStep) break
        assemblystream.push({...currentTrip, type: 'vertex'})
        assemblystream.push({...nextStep, type: 'node'})
        currentStep = nextStep

        cnt++
      }

      if (hasDownstream) {
        assemblystream.push({type: 'vr'})
      }

      return assemblystream
    }
  }
}
</script>

<style scoped></style>
