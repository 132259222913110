<template>
  <l-marker
    :lat-lng="[supplier.supplier.latitude,supplier.supplier.longitude]"
    :icon="getIcon(supplier)"
  >
    <l-tooltip :content="
      '<span class=\'neocondo-text-bold\'>'
      + supplier.name
      + '</span>'
      + (supplier.supplier.name
        ? '<br>' + supplier.supplier.name
        : ''
      )
      + (supplier.supplier.address
        ? '<br>' + shortenLongString(supplier.supplier.address)
        : ''
      )
      + '<br>'
      + supplier.supplier.country
      + ', '
      + supplier.supplier.continent
    " />
  </l-marker>
</template>

<script>
import * as L from "leaflet"

export default {
  name: 'LeafletMarker',
  props: {
    supplier: {
      type: Object,
      default() {
        return {}
      }
    },
    icon: {
      type: String,
      default: null
    }
  },
  methods: {
    shortenLongString(str) {
      let i = 0, j = -1, k = 0, maxLen = 40, result = ''
      while (str.length > 0) {
        if (str.length <= maxLen) {
          if (result.length > 0) {
            result += '<br>'
          }
          result += str
          i += maxLen
        } else {
          k = 0
          while((j < 0) && (k < str.length)) {
            k += maxLen
            j = str.lastIndexOf(' ', k)
            if (j < 0) {
              j = str.lastIndexOf(',', k)
            }
          }
          if (j < 0) {
            j = k
          }
          if (result.length > 0) {
            result += '<br>'
          }
          result += str.substr(0, j)
          i = j + 1
        }
        str = str.substr(i)
      }
      return result
    },
    getIcon() {
      return L.icon({
        iconUrl: 'https://neocondo-assets.s3.eu-west-3.amazonaws.com/' + (
          this.icon
          ? this.icon
          : (
            this.supplier.icon
            ? this.supplier.icon
            : 'processing.svg'
          )
        ),
        iconSize: 36
      })
    }
  }
}
</script>

<style scoped></style>
