<template>
  <b-row>
    <b-col
      v-if="transports.train > 0"
      :cols="displayCols ? 'auto': 12"
      :class="{ 'px-2': displayCols }"
    >
      <b-row>
        <b-col cols="auto" class="pr-0 my-auto">
          <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/train.png" class="neocondo-icon-xtiny">
        </b-col>
        <b-col class="pl-2 mt-auto">
          <p
            class="neocondo-text-gray"
            :class="$device.isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
          >{{ prettifyBigAmount(transports.train) }} km</p>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="transports.truck > 0"
      :cols="displayCols ? 'auto': 12"
      :class="{ 'px-2': displayCols }"
    >
      <b-row>
        <b-col cols="auto" class="pr-0 my-auto">
          <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/truck.png" class="neocondo-icon-xtiny">
        </b-col>
        <b-col class="pl-2 mt-auto">
          <p
            class="neocondo-text-gray"
            :class="$device.isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
          >{{ prettifyBigAmount(transports.truck) }} km</p>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="transports.van > 0"
      :cols="displayCols ? 'auto': 12"
      :class="{ 'px-2': displayCols }"
    >
      <b-row>
        <b-col cols="auto" class="pr-0 my-auto">
          <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/van.png" class="neocondo-icon-xtiny">
        </b-col>
        <b-col class="pl-2 mt-auto">
          <p
            class="neocondo-text-gray"
            :class="$device.isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
          >{{ prettifyBigAmount(transports.van) }} km</p>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="transports.car > 0"
      :cols="displayCols ? 'auto': 12"
      :class="{ 'px-2': displayCols }"
    >
      <b-row>
        <b-col cols="auto" class="pr-0 my-auto">
          <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/car.png" class="neocondo-icon-xtiny">
        </b-col>
        <b-col class="pl-2 mt-auto">
          <p
            class="neocondo-text-gray"
            :class="$device.isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
          >{{ prettifyBigAmount(transports.car) }} km</p>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="transports.boat > 0"
      :cols="displayCols ? 'auto': 12"
      :class="{ 'px-2': displayCols }"
    >
      <b-row>
        <b-col cols="auto" class="pr-0 my-auto">
          <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/boat.png" class="neocondo-icon-xtiny">
        </b-col>
        <b-col class="pl-2 mt-auto">
          <p
            class="neocondo-text-gray"
            :class="$device.isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
          >{{ prettifyBigAmount(transports.boat) }} km</p>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="transports.airplane > 0"
      :cols="displayCols ? 'auto': 12"
      :class="{ 'px-2': displayCols }"
    >
      <b-row>
        <b-col cols="auto" class="pr-0 my-auto">
          <img src="https://neocondo-assets.s3.eu-west-3.amazonaws.com/airplane.png" class="neocondo-icon-xtiny">
        </b-col>
        <b-col class="pl-2 mt-auto">
          <p
            class="neocondo-text-gray"
            :class="$device.isMobile ? 'neocondo-text-tiny' : 'neocondo-text-xsmall'"
          >{{ prettifyBigAmount(transports.airplane) }} km</p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Transports',
  props: {
    transports: {
      type: Object,
      default() {
        return {}
      }
    },
    displayCols: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    prettifyBigAmount(amount) {
      return parseInt(amount.toFixed(0)).toLocaleString()
    }
  }
}
</script>

<style scoped></style>
