<script>
import { Doughnut } from "vue-chartjs"
import ChartPluginLabels from 'chartjs-plugin-labels';

export default {
  name: 'GaugeChart',
  extends: Doughnut,
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    plugins: {
      type: Array,
      default: () => [ChartPluginLabels],
    }
  },
  mounted() {
    this.addPlugin({
      afterDraw: chart => {
        var needleValue = chart.chart.config.data.datasets[0].needleValue;
        var dataTotal = chart.chart.config.data.datasets[0].data.reduce((a, b) => a + b, 0);
        var angle = Math.PI + (1 / dataTotal * needleValue * Math.PI);
        var ctx = chart.chart.ctx;
        var cw = chart.chart.canvas.offsetWidth;
        var ch = chart.chart.canvas.offsetHeight;
        var cx = cw / 2;
        var cy = ch + cx;

        ctx.translate(cx, cy/2);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(0, -3);
        ctx.lineTo(5*cx/8, 0);
        ctx.lineTo(0, 3);
        ctx.fillStyle = 'rgb(0, 0, 0)';
        ctx.fill();
        ctx.rotate(-angle);
        ctx.translate(-cx, -cy/2);
        ctx.beginPath();
        ctx.arc(cx, cy/2, 5, 0, Math.PI * 2);
        ctx.fill();
      }
    })
    this.rebuildChart()
  },
  computed: {
    chartData() {
      return this.data
    }
  },
  watch: {
    data() {
      this.rebuildChart()
    },
    options() {
      this.rebuildChart()
    }
  },
  methods: {
    rebuildChart() {
      try {
        this._chart.destroy()
      } catch (err) { undefined }
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>

<style scoped>
</style>