import Vue from 'vue'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import device from 'vue-device-detector'
import App from './App.vue'
import store from './store'
import i18n from './i18n'
import vueCustomElement from 'vue-custom-element'

import * as L from 'leaflet'
import { Icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPolyline, LTooltip } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import './assets/styles/main.scss'
import './assets/styles/grid.scss'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(vueCustomElement)
Vue.use(device)
App.router = VueRouter
App.store = store
App.i18n = i18n
Vue.customElement(
  'vue-widget',
  App,
  {
    shadow: true,
    beforeCreateVueInstance(root) {
      const rootNode = root.el.getRootNode()
      if (rootNode instanceof ShadowRoot) {
        root.shadowRoot = rootNode
      } else {
        root.shadowRoot = document.head
      }
      return root
    }
  }
)
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-polyline', LPolyline)
Vue.component('l-tooltip', LTooltip)
Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)
const LeafletPlugin = {
  install(Vue) {
    Vue.prototype.$L = L
  }
}
Vue.use(LeafletPlugin)

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})